
    import { defineComponent } from "vue";
    import vm from '@/main';
    import { sellPlanModel} from "@/models/WorkspaceModel";
    import WorkspaceSellplanApi from "@/apis/WorkspaceSellPlanApi";
    import SecondNavbar from "@/components/SecondNavbar.vue";

    export default defineComponent({
        name: "SellPlanWorkspace",
        components: {
            SecondNavbar,
        },
        data() {
            return {
                sellPlanFolder: new Array<sellPlanModel>(),
                searchKeyword:"",
                pages:{
                    pageNumber: 1, // 目前頁碼，從1開始
                    pageSize: 30, // 頁面大小，任意>0
                    totalPages: 3, // 總頁面數，從0開始
                },
            };
        },
        mounted() {

        },
        methods: {
            editSellPlan(index: number){
                this.$store.commit('changeSellPlanWorkspace', this.sellPlanFolder[index]);
                this.$router.push({
                    name: "EditSellPlan",
                });
            },
            clearKeyword(){
                console.log("Debug: clearKeyword");
                // this.searchKeyword = "";
                this.searchByname("");
            },
            async searchByname(keyword:string){
                this.searchKeyword = keyword;
                console.log("Debug: buttonSearch keyword= ", this.searchKeyword);
                this.$store.commit('updateLoading', true);
                try{
                    const sellPlanQueryResult = JSON.parse(JSON.stringify(await WorkspaceSellplanApi.getSellPlanByName(this.searchKeyword, 1)));
                    this.sellPlanFolder = sellPlanQueryResult.results.results;
                    this.pages.totalPages = sellPlanQueryResult.results.totalPages;
                    this.pages.pageNumber = sellPlanQueryResult.results.pageNumber;
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            async onPageChange(newPage:number){
                console.log("Debug: onPageChange newPage= ", newPage);
                console.log("Debug: onPageChange buttonSearch keyword= ", this.searchKeyword);
                if(newPage < 1)  newPage = 1;
                else if(newPage > this.pages.totalPages)    newPage = this.pages.totalPages
                this.$store.commit('updateLoading', true);
                try{
                    const sellPlanQueryResult = JSON.parse(JSON.stringify(await WorkspaceSellplanApi.getSellPlanByName(this.searchKeyword ,newPage)));
                    this.sellPlanFolder = sellPlanQueryResult.results.results;
                    this.pages.totalPages = sellPlanQueryResult.results.totalPages;
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            async deleteSellPlan(index:number){
                const courseName = this.sellPlanFolder[index].metadata.name;
                this.$bvModal.msgBoxConfirm(`确定要刪除「${courseName}」吗?`,{
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: '确定',
                    cancelTitle: '取消',
                    footerClass: 'p-2',
                    hideHeaderClose: true,
                    centered: true
                })
                .then(async value => {
                if(value) {
                    const userConfirmation = prompt("确认要刪除吗？（输入“确认刪除”进行刪除）");
                    if(userConfirmation == null){
                        return false;
                    }
                    else if (userConfirmation !== "确认刪除") {
                        alert("輸入錯誤")
                        this.deleteSellPlan(index);
                        return false;
                    }
                    else{
                        try{
                            await WorkspaceSellplanApi.deleteSellPlan(this.sellPlanFolder[index].uuid);
                            this.sellPlanFolder.splice(index, 1);
                            alert("刪除成功")
                        }
                        catch(e){
                            console.log(e);
                        }
                        finally{

                            this.$store.commit('updateLoading', false);
                        }
                    }
                }
                })
                .catch(err => {
                // An error occurred
                })
            },
        },
        async created(){
            this.$store.commit('updateLoading', true);
            try{
                console.log(this.pages.pageNumber)
                const sellPlanQueryResult = JSON.parse(JSON.stringify(await WorkspaceSellplanApi.getSellPlanByName("", this.pages.pageNumber)));
                this.sellPlanFolder = sellPlanQueryResult.results.results;
                this.pages.totalPages = sellPlanQueryResult.results.totalPages;
                this.pages.pageNumber = sellPlanQueryResult.results.pageNumber;
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.$store.commit('updateLoading', false);
            }
        }
    });
